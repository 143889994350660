import React, { useContext } from "react"

import { CircleCheck } from "akar-icons"
import PropTypes from "prop-types"

import { AuthContext } from "../../store/auth"
import Button from "../common/Button"

import "./ChangeEmailConfirmMessage.scss"

const ChangeEmailConfirmMessage = ({ token }) => {
  const { confirmEmailUpdate } = useContext(AuthContext)

  return (
    <div className="change-email-confirm">
      <div className="change-email-confirm__container">
        <div className="change-email-confirm__tick-container">
          <CircleCheck size={72} color="#303546" />
        </div>
        <p className="change-email-confirm__text">
          To successfully change your email, tap on the button below
        </p>
        <Button
          color="primary"
          isBlock
          onClick={() => confirmEmailUpdate(token)}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

ChangeEmailConfirmMessage.propTypes = {
  token: PropTypes.string.isRequired,
}

export default ChangeEmailConfirmMessage
