import React, { useEffect, useState } from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import ChangeEmailConfirmMessage from "../components/auth/ChangeEmailConfirmMessage.js"
import SEO from "../components/seo.js"

const ChangeEmailConfirm = ({ location }) => {
  const [isMounted, setIsMounted] = useState(false)

  const params = new URLSearchParams(location.search)
  const token = params.get("token")

  useEffect(() => {
    if (!token) {
      navigate("/change-email")
      return
    }
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return <div></div>
  }

  return (
    <>
      <SEO title="Email Change Confirm" path="/email-change-confirm/" />
      <ChangeEmailConfirmMessage token={token} />
    </>
  )
}

ChangeEmailConfirm.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ChangeEmailConfirm
